<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
// import scrollgridPlugin from "@fullcalendar/scrollgrid";
import listPlugin from "@fullcalendar/list";


import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ProcedureService from '@/services/procedure-service'

import { categories } from "./data-calendar";


/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description" }],
  },
  components: { FullCalendar, Layout, PageHeader },
  data() {
    return {
      title: "Calendar",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      calendarEvents: {},
      calendarOptions: {
      buttonText:  {
          today:    'hoje',
          month:    'mês',
          week:     'semana',
          day:      'dia',
          list:     'lista'
        },
        locale: 'pt-br',
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
          // scrollgridPlugin,
        ],
        titleFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
        initialView: "timeGridWeek",
        themeSystem: "bootstrap",
        initialEvents: {},
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
      filters: {

      },
      params: {
        size: 500000,
        dateStart: new Date(),
        dateEnd: new Date(),
      },
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  created() {
    this.service = new ProcedureService(this.$http)
    var now = new Date()
    let day = 24*60*60*1000
    console.log(now , now.getTime() )
    console.log("start: ", new Date(now.getTime() - (20 * day)))

    this.params.dateStart = new Date(now.getTime() - (20 * day))
    this.params.dateEnd =  new Date(now.getTime() + (20 * day))
    console.log(this.params)
    this.chargeEvents();

  },
  methods: {
    chargeEvents() {
        this.loading = true
        this.service.list(this.params).then((res) => {
            console.log(res)
            var proceduresData = res.data.content
            var calendarApi = this.$refs.fullCalendar.getApi();
            

            var events = calendarApi.getEvents()
            console.log(events)
            if(events){
              events.forEach(event => {
                console.log(event);
                event.remove();
              })
            }

            
            this.calendarEvents =  proceduresData.map(procedure => {
              const event = {
                id: procedure.id,
                title: procedure.patient.name,
                start: procedure.dateTimeStart,
                class: 'bg-success text-white'
              }

              
              this.currentEvents = calendarApi.addEvent({
                id: procedure.id,
                title: procedure.patient.name + " ( " + procedure.professional.name + " | " + procedure.professional.activity + " | Sala " + procedure.room.name + " )" ,
                start: procedure.dateTimeStart,
                end: procedure.dateTimeEnd,
                classNames: ['bg-success', 'text-white'],
              });

              return event
            })

            
            this.calendarOptions.events = this.calendarEvents
          
            
        

            this.loading = false
        }),(err) => {
            console.error(err)
        }
        this.loading = false
    },

    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const category = this.event.category;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: [category],
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },


    filtrar(){
      this.params.patientName= this.filters.pacienteFilter;
      this.chargeEvents();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Filters: </h4>
            <div class="form-group">
            <label for="name">Paciente</label>
            <input
              id="name"
              v-model="filters.pacienteFilter"
              type="text"
              class="form-control"
              placeholder="Digite o nome"
              :class="{ 'is-invalid': submitted && $v.event.title.$error }"
            />
            </div>
            <div class="text-right pt-2 mt-3">
              <b-button type="button" @click="filtrar" variant="success" class="ml-1"
                >Filtrar</b-button
              >
            </div>
              
          </div>
          <div class="card-body">
            
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading"  class="d-flex justify-content-center">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <b-modal
      v-model="showModal"
      title="Add New Event"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Event Name</label>
              <input
                id="name"
                v-model="event.title"
                type="text"
                class="form-control"
                placeholder="Insert Event name"
                :class="{ 'is-invalid': submitted && $v.event.title.$error }"
              />
              <div
                v-if="submitted && !$v.event.title.required"
                class="invalid-feedback"
              >
                This value is required.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Category</label>
              <select
                v-model="event.category"
                class="form-control"
                name="category"
                :class="{ 'is-invalid': submitted && $v.event.category.errors }"
              >
                <option
                  v-for="option in categories"
                  :key="option.backgroundColor"
                  :value="`${option.value}`"
                >
                  {{ option.name }}
                </option>
              </select>
              <div
                v-if="submitted && !$v.event.category.required"
                class="invalid-feedback"
              >
                This value is required.
              </div>
            </div>
          </div>
        </div>

        <div class="text-right pt-5 mt-3">
          <b-button variant="light" @click="hideModal">Close</b-button>
          <b-button type="submit" variant="success" class="ml-1"
            >Create event</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      title="Edit Event"
      title-class="text-black font-18"
      hide-footer
      body-class="p-0"
    >
      <form @submit.prevent="editSubmit">
        <div class="p-3">
          <label>Change event name</label>
          <div class="input-group m-b-15">
            <input
              v-model="editevent.editTitle"
              class="form-control"
              type="text"
            />
            <span class="input-group-append">
              <button type="submit" class="btn btn-success btn-md">
                <i class="fa fa-check"></i> Save
              </button>
            </span>
          </div>
        </div>
        <div class="text-right p-3">
          <b-button variant="light" @click="closeModal">Close</b-button>
          <b-button class="ml-1" variant="danger" @click="deleteEvent"
            >Delete</b-button
          >
        </div>
      </form>
    </b-modal>
  </Layout>
</template>


